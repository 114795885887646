<template>
  <div class="sobre_section_bc-color">
    <div class="d-none d-sm-flex">
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-start align-start">
          <slot name="breadcrumbLinks" />
          <router-link
            tag="p"
            :to="{ name: link.href }"
            v-for="(link, idx) in items"
            :key="idx"
          >
            {{ link.text }}
          </router-link>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex d-md-none" v-if="this.$route.meta.showGoBack != false">
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-start align-start">
          <GoBack />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import GoBack from "../components/GoBack.vue";

export default {
  components: { GoBack },
  props: {
    items: {
      type: Array,
    },
    color: {
      type: String,
      default: "black",
    },

    lastLinkColor: {
      type: String,
    },
  },
};
</script>

<style scoped>
p {
  font-size: 14px;
  margin-bottom: 0;
  cursor: pointer;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: none;
}
</style>
