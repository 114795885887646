<template>
  <div class="go-back-button d-md-none d-flex justify-center align-center">
    <v-img max-width="25px" @click="goBack()" src="./assets/mobile_goback_icon.png"></v-img>
  </div>
</template>

<script>
export default {

  methods: {
    goBack () {
      window.history.back()
    }
  }

}
</script>

<style scoped>

.go-back-button {
  position: absolute;
  left: 1em;
  top: 1em;
}

</style>